// general
import Antispam from '../../components/Antispam'
// import CookieBubble from '../../components/CookieBubble'
// project
import Animation from './Animation'
import Carousel from './Carousel'
import ContactMap from './ContactMap'
import Filter from './Filter'
import FilterMap from './FilterMap'
import FixableHeader from './FixableHeader'
import FormNewsletter from './FormNewsletter'
import Gallery from './Gallery'
import GasMap from './GasMap'
import InputUpload from './InputUpload'
import Modal from './Modal'
import ModalOpener from './ModalOpener'
import NavOpener from './NavOpener'
import Notification from './Notification'
import PageEdit from './PageEdit'
import Slider from './Slider'
import Switch from './Switch'
import Tabs from './Tabs'

const Components = {
    Antispam,
    Animation,
    // CookieBubble,
    Carousel,
    ContactMap,
    Gallery,
    GasMap,
    Switch,
    Filter,
    FilterMap,
    FixableHeader,
    FormNewsletter,
    InputUpload,
    NavOpener,
    Notification,
    Modal,
    ModalOpener,
    PageEdit,
    Slider,
    Tabs,
}

export default Components
